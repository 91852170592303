export default function () {
  return [
    {
      title: "Создал",
      departments: [
        {
          emps: ["Жургенов Абас"],
        },
      ],
    },
    {
      title: "Согласующие",
      departments: [
        {
          title: "Название Отдела",
          emps: ["Фамилия Имя"],
        },
        {
          title: "Название Отдела",
          emps: ["Фамилия Имя"],
        },
      ],
    },
    {
      title: "Исполнители",
      departments: [
        {
          title: "Название Отдела",
          emps: ["Фамилия Имя", "Фамилия Имя", "Фамилия Имя"],
        },
        {
          title: "Название Отдела",
          emps: [
            "Фамилия Имя",
            "Фамилия Имя",
            "Фамилия Имя",
            "Фамилия Имя",
            "Фамилия Имя",
          ],
        },
      ],
    },
  ];
}
